<template>
    <div class="config">
        <div>订单详情</div>
        <div style="height: 95%;">
            <div class="content-box">
                <div class="flex flex-child-center flex-justify-content-between">
                    <div style="font-size: 0.25rem;color: #333333;"><span style="margin-right: 0.2rem;">订单号：{{data.order_num}}</span><span style="font-size: 0.14rem;
            font-weight: 400;
            color: #4B83F0;">
                        {{data.pay_status_text}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{data.status_text}}</span></div>
                    <div class="flex" >
                        <div class="buttonBox" @click="cancel"  v-if="data.status == 0 && data.pay_status == 0">取消订单</div>
<!--                        <div class="buttonBox"  v-if="data.status == 0 || data.status == 1 || data.status == 2">修改地址</div>-->
                        <div class="buttonBox" @click="pay" style="border-color: #4B83F0;color: #4B83F0;margin-right: 0;" v-if="data.pay_status == 0">付款</div>
                    </div>
                </div>
                <div style="width: 100%">
                    <div class="content-main-box" v-for="(item1,index1) in data.goods_list">
                        <div class="content-main-box-image">
                            <img v-if="item1.goods_image" :src="item1.goods_image">
                            <img v-else src="../assets/images/moreImage.png">
                        </div>
                        <div>
                            <div class="content-main-box-text">
                                {{item1.goods_name}}
                            </div>
                            <div  class="content-main-box-spec">
                                {{item1.goods_spec}}
                            </div>
                            <div class="content-main-box-price">
                                <span >￥{{item1.price}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style ="width:100%">
                    <div class="content-main-adress">
                         <div>
                            <div>
                                <span style="font-size: 0.2rem;color: #333333;">收货信息</span>
                            </div>
                            <div class="content-main-adressName">
                                <span class="content-main-adressName-key">收 件 人</span>
                                <span class="content-main-adressName-value">{{data.address_info.connect_name}}</span>
                            </div>
                            <div class="content-main-adressPhone">
                                <span class="content-main-adressPhone-key">联系电话</span>
                                <span class="content-main-adressPhone-value">{{data.address_info.connect_mobile}}</span>
                            </div>
                            <div class="content-main-adressAdr">
                                <span class="content-main-adressAdr-key">收件地址</span>
                                <span class="content-main-adressAdr-value">{{data.address_info.detail_address}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style ="width:100%">
                    <div class="content-main-pay">
                        <div>
                            <div>
                                <span style="font-size: 0.2rem;color: #333333;">支付方式</span>
                            </div>
                            <div class="content-main-pay-name">
                                <span class="content-main-pay-name-key">支付方式</span>
                                <span class="content-main-pay-name-value">{{data.address_info.connect_name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width:100%">
                    <div class="content-main-amount">
                        <div>
                            <div class="content-main-amount-sum">
                                <span class="content-main-amount-sum-key">商品总价：</span>
                                <span class="content-main-amount-sum-value">￥{{ data.goods_total_price }}</span>
                            </div>
                            <div class="content-main-amount-yh">
                                <span class="content-main-amount-yh-key">优惠金额：</span>
                                <span class="content-main-amount-yh-value">-￥{{ data.discounts_price }}</span>
                            </div>
                            <div class="content-main-amount-sf">
                                <span class="content-main-amount-sf-key">实付金额：</span>
                                <span class="content-main-amount-sf-value">￥{{ data.order_price }}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "../store";

export default {
    name: "orderDetails",
    data() {
        return {
            form: {},
            data:{}
        }
    },
    mounted() {
        this.form = this.$route.query
        this.onList();
    },
    methods: {
        pay() {
            let a = {
                pay_type:this.data.pay_type,
                data:this.data,
                totalPrice:this.data.order_price
            }
            this.$store.commit('setPay', a)
            this.$router.push('/pay');
        },
        /**
         * 取消订单
         * */
        cancel() {
            this.$confirm('确认取消该订单？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post('api/kuerp/order/index/cancelOrder', this.$qs.stringify({
                    order_num:this.form.order_num,
                })).then(res => {
                    this.$router.push({
                        name: 'order',
                    })
                })
            }).catch(() => {
                // this.$message({
                //     type    : 'info',
                //     message : '已取消删除'
                // });
            });
        },
        onList() {
            this.$axios.post('api/kuerp/order/index/orderInfo', {
                order_num:this.form.order_num,
            }).then(res => {
                if(res.data.code == 200) {
                    this.data = res.data.data
                }
            })
        },

    },
}
</script>

<style lang="scss" scoped>
.content-main-box {
    padding: 0.11rem 0.15rem;
    display: flex;
    align-items: center;
    border-bottom: 0.01rem solid #F2F2F2;
    border-right: 0.01rem solid #F2F2F2;
    .content-main-box-image {
        display: flex;
        align-items: center;
        img {
            width: 0.6rem;
            height: 0.6rem;
            margin-right: 0.1rem;
        }
    }
    .content-main-box-text {
        width: 100%;
        font-size: 0.16rem;
        font-weight: 500;
        color: #393E4C;
        display: flex;
        align-items: center;
        margin-bottom: 0.1rem;
    }
    .content-main-box-spec {
        font-size: 0.12rem;
        font-weight: 400;
        color: #999999;
        line-height: 0.18rem;
        margin-bottom: 0.06rem;
    }
    .content-main-box-price {
        font-size: 0.18rem;
        font-weight: bold;
        color: #333333;
        span {
            display: block;
            width: 1rem;
        }
    }
}

.content-main-amount{
    padding-top: 0.3rem;
    padding-left: 80%;
    display: flex;
    border-right: 0.01rem solid #F2F2F2;
    .content-main-amount-sum{
        height: 0.4rem;
        margin-bottom: 0.25rem;
        .content-main-amount-sum-key{
            font-size: 0.2rem;
            font-weight: 400;
            color: #999999;
            line-height: 0.2rem;
        }
        .content-main-amount-sum-value{
            margin-left: 0.2rem;
            font-size: 0.2rem;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #4B83F0;
            line-height: 0.2rem;
        }
    }
    .content-main-amount-yh{
        height: 0.4rem;
        margin-bottom: 0.25rem;
        .content-main-amount-yh-key{
            margin-top: 0.1rem;
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 0.2rem;
        }
        .content-main-amount-yh-value{
            margin-left: 0.2rem;
            margin-top: 0.1rem;
            font-size: 0.2rem;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #4B83F0;
            line-height: 0.2rem;
        }
    }
    .content-main-amount-sf{
        height: 0.4rem;
        .content-main-amount-sf-key{
            width: 10.2rem;
            height: 0.24rem;
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 0.24rem;
        }
        .content-main-amount-sf-value{
            margin-left: 0.2rem;
            margin-top: 0.1rem;
            font-size: 0.32rem;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #CF7AE2;
            line-height: 0.28rem;
        }
    }
}

.content-main-pay {
    padding: 0.11rem 0.15rem;
    display: flex;
    border-bottom: 0.01rem solid #F2F2F2;
    border-right: 0.01rem solid #F2F2F2;
    font-size: 0.2rem;
    font-weight: 500;
    line-height: 0.28rem;
    .content-main-pay-name{
        .content-main-pay-name-key{
            width: 0.48rem;
            height: 0.12rem;
            font-size: 0.12rem;
            font-weight: 400;
            color: #666666;
            line-height: 0.12rem;
        }
        .content-main-pay-name-value{
            margin-left: 0.1rem;
            font-size: 0.12rem;
            font-weight: 500;
            color: #2F2F2F;
            line-height: 0.12rem;
        }
    }
}


.content-main-adress {
    padding: 0.11rem 0.15rem;
    display: flex;
    border-bottom: 0.01rem solid #F2F2F2;
    border-right: 0.01rem solid #F2F2F2;
    font-size: 0.2rem;
    font-weight: 500;
    line-height: 0.28rem;
    .content-main-adressName{
       .content-main-adressName-key {
           width: 0.48rem;
           height: 0.12rem;
           font-size: 0.12rem;
           font-weight: 400;
           color: #666666;
           line-height: 0.12rem;
       }
        .content-main-adressName-value {
            margin-left: 0.13rem;
            font-size: 0.12rem;
            font-weight: 500;
            color: #2F2F2F;
            line-height: 0.12rem;
        }
    }
    .content-main-adressPhone{
        .content-main-adressPhone-key{
            width: 0.48rem;
            height: 0.12rem;
            font-size: 0.12rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 0.12rem;
        }
        .content-main-adressPhone-value {
            margin-left: 0.1rem;
            font-size: 0.12rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F2F2F;
            line-height: 0.12rem;
        }
    }
    .content-main-adressAdr{
        .content-main-adressAdr-key {
            width: 0.48rem;
            height: 0.12rem;
            font-size: 0.12rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 0.12rem;
        }
        .content-main-adressAdr-value{
            margin-left: 0.1rem;
            font-size: 0.12rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2F2F2F;
            line-height: 0.12rem;
        }
    }
}
.buttonBox {
    width: 0.78rem;
    height: 0.26rem;
    border-radius: 0.16rem;
    border: 0.01rem solid #E1E1E1;
    font-size: 0.12rem;
    font-weight: 400;
    color: #2F2F2F;
    line-height: 0.26rem;
    text-align: center;
    margin-bottom: 0.2rem;
    margin-top: 0.1rem;
    margin-right: 0.05rem;
    cursor: pointer;
}
.content-box {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 0.1rem;
}
.config {
    height: 100%;
    padding: 0.24rem 0.34rem;
    >div:nth-child(1) {
        font-size: 0.18rem;
        font-weight: 400;
        color: #333333;
        padding-bottom: 0.1rem;
        border-bottom: 0.01rem solid #E4E4E4;
    }

}
</style>
